// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/workspace/fe-organization-pc_ZFrk/node_modules/.pnpm/@umijs+runtime@3.5.20_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/404",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404__index' */'@/pages/404/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/500",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__500__index' */'@/pages/500/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/:portalCode",
        "routes": [
          {
            "path": "/:portalCode/404",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__portalCode__404__index' */'@/pages/[portalCode]/404/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:portalCode/home",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__portalCode__home__index' */'@/pages/[portalCode]/home/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:portalCode/keepalive",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__portalCode__keepalive' */'@/pages/[portalCode]/keepalive.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:portalCode/micro",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__portalCode__micro__index' */'@/pages/[portalCode]/micro/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:portalCode/micro/microPageFc",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__portalCode__micro__microPageFc' */'@/pages/[portalCode]/micro/microPageFc.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:portalCode/picture/detail",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__portalCode__picture__detail__index' */'@/pages/[portalCode]/picture/detail/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:portalCode/picture/list",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__portalCode__picture__list__index' */'@/pages/[portalCode]/picture/list/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:portalCode/practice/list",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__portalCode__practice__list__index' */'@/pages/[portalCode]/practice/list/index.tsx'), loading: LoadingComponent})
          }
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__portalCode___layout' */'@/pages/[portalCode]/_layout.tsx'), loading: LoadingComponent})
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404__index' */'@/pages/404/index.tsx'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
